<template>
  <div class="d-flex flex-column align-items-center min-vh-100">
    <!-- Main Content Area -->
    <div class="flex-grow-1 d-flex flex-column align-items-center justify-content-center">
      <!-- Formulario NPS -->
      <div v-if="questions.length">
        <b-card v-if="!loading" class="card-nps py-5 px-4">
          <template v-for="(question, index) in questions">
            <NPS :loading="loading" :total="questions.length" :current="current_step" :key="index" :question="question"
              v-if="current_step === index" @next="handleNextQuestion" />
          </template>
        </b-card>
        <b-card v-else class="card-nps py-5 px-4 text-center">
          <Logo class="mb-4" :height="is_mobile ? '35' : '40'" />
          <h2>A Maat agradece pela sua contribuição!</h2>
        </b-card>
      </div>
      <!-- Pesquisa de satisfação já respondida -->
      <div v-else>
        <b-card class="py-5 px-4 text-center">
          <Logo class="mb-4" :height="is_mobile ? '35' : '40'" />
          <h2>{{ thankYouText }}</h2>
        </b-card>
      </div>
    </div>
    <!-- Footer -->
    <footer class="font-small application-footer w-100 text-center py-3 mt-auto">
      Maat © {{ new Date().getFullYear() }} | Todos os direitos reservados
    </footer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { api } from "@/services/api";
import NPS from "./NPS.vue";
import Logo from "@/components/Images/Logo";

export default {
  components: {
    NPS,
    Logo,
  },
  data() {
    return {
      current_step: 0,
      total_steps: 0,
      loading: false,
      questions: [],
      description: "",
      thankYouText: "",
      formId: null,
      formKey: null,
    };
  },
  computed: {
    ...mapGetters([
      "is_mobile",
    ]),
  },
  methods: {
    handleNextQuestion() {
      this.current_step += 1;

      if (this.current_step === this.questions.length) {
        this.onSubmit();
      }
    },

    getQuestion() {
      api
        .get(`form/application/${this.formId}/?key=${this.formKey}`)
        .then((response) => {
          // { data: { questions, description, thankYouText } }
          if (response.status == 200) {
            this.questions = response.data.questions;
            this.total_steps = response.data.questions.length;
            this.description = response.data.description;
            this.thankYouText = response.data.thankYouText;
          } else {
            this.thankYouText = response.data.errors[0].message
          }
        });
    },

    onSubmit() {
      this.loading = true;

      const questions = this.questions.map(({ id, answer }) => ({
        id,
        answer,
      }));

      api
        .patch(`form/application/answer/${this.formId}/`, {
          questions,
          form: this.formId,
          key: this.formKey,
        })
        .then(() => {
          setTimeout(() => {
            this.loading = false;
            window.location.href = "https://maatgestao.com";
          }, 1000);
        })
    },
  },
  mounted() {
    // O usuário não precisa estar logado nessa tela para responder a pesquisa
    if (localStorage.getItem('token')) {
      // Remove the token and user
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      // Refresh the page
      location.reload();
    }

    this.formId = this.$route.query.id;
    this.formKey = this.$route.query.key;
    this.getQuestion();
  },
};
</script>
<style lang="scss" scoped>
.card-nps {
  max-width: 740px;
}

.min-vh-100 {
  min-height: 100vh;
}

/* Centering the footer at the bottom */
.application-footer {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>
